import React from 'react';
// import PaymentForm from '../Components/PaymentForm';
import { StripeProvider, Elements } from 'react-stripe-elements';
import PaymentForm from '../Components/PaymentForm';

function Yay() {

return (
    <div>
        <PaymentForm />
    </div>
    );
};

export default Yay;
