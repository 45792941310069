import { useState, useEffect } from 'react';
import { getDatabase, onValue, ref, remove, get, child, update, set } from "firebase/database";
import { database } from '../FirebaseConfig';
import { useNavigate, useLocation } from 'react-router-dom';
import firebase from 'firebase/compat/app';
import VerifyEmailMsg from '../Components/VerifyEmailMsg';

function handleDeleteReview(reviewId, parentKey, userUid) {
  // Define references for the review and its parent node
  const reviewRef = ref(database, `Review/${parentKey}/${reviewId}`);
  const parentRef = ref(database, `Review/${parentKey}`);
  // Define reference for the user's submission key
  const submissionsRef = ref(database, `Users/${userUid}/Submissions`);

  // Start deletion process
  get(parentRef).then((parentSnap) => {
      if (parentSnap.exists()) {
          // Check if the review is the last one under its parent key
          if (Object.keys(parentSnap.val()).length > 1) {
              // More than one child, delete just the review
              remove(reviewRef);
          } else {
              // Only one child, delete the entire parent key
              remove(parentRef);
          }
          // Now delete the submission key associated with this review
          get(submissionsRef).then((snap) => {
              if (snap.exists()) {
                  const submissions = snap.val();
                  const updatedSubmissions = Object.entries(submissions).reduce((acc, [key, value]) => {
                      if (!(value.reviewId === reviewId && value.parentKey === parentKey)) {
                          acc[key] = value; // Keep this submission
                      }
                      return acc;
                  }, {});

                  // Update the submissions node with the remaining submissions
                  set(submissionsRef, updatedSubmissions);
              }
          });
      }
  }).catch((error) => console.error("Error deleting review: ", error));
}


export function ReviewCard({ children, reviewId, parentKey, onDelete }) {
  return (
    <div className='review-card'>
      <h3>Review Card</h3>
      {children}
      <button onClick={() => onDelete(reviewId, parentKey)}>Delete Review</button>
    </div>
  );
}


function deleteAccountAuth({user}, {navigate}, {setDeleting}) {
  const db = firebase.firestore();
  db.collection('users').doc(user.currentUser.uid).delete()
        .then(() => 
          {
            // Step 2: Delete the user from the authentication system
            console.log(user.currentUser);
            user.currentUser.delete().then(() => {
                console.log("User deleted");
                navigate('/login');
            })
          }
        ).catch((error) => {
            console.error("Error removing document: ", error);
            setDeleting(false);
        });
}

function deleteAccount({user}, {setDeleting}, database) {
  const button = document.getElementById('deleteButton');
  button.disabled = true; // Disables the button to prevent multiple requests
  // const userId = user.currentUser?.uid;

  // Path to the user's submissions
  const submissionsRef = ref(database, `Users/${user.currentUser?.uid}/Submissions`);
  
  get(submissionsRef).then((snapshot) => {
    if (snapshot.exists()) {
      console.log(snapshot.val());
      const submissions = snapshot.val();
      const deletionPromises = [];

      Object.keys(submissions).forEach((submissionKey) => {
        console.log(submissions[submissionKey]);
        const [reviewParentKey, reviewId] = submissions[submissionKey];
        console.log(`Review/${reviewParentKey}/${reviewId}`);
        const reviewRef = ref(database, `Review/${reviewParentKey}/${reviewId}`);
        const parentRef = ref(database, `Review/${reviewParentKey}`);

        // Check if the review is the last one under its parent key
        deletionPromises.push(
          get(parentRef).then((parentSnap) => {
            if (parentSnap.exists()) {
              if (Object.keys(parentSnap.val()).length > 1) {
                // More than one child, delete just the review
                return remove(reviewRef);
              } else {
                // Only one child, delete the entire parent key
                return remove(parentRef);
              }
            }
          })
        );
      });

      Promise.all(deletionPromises).then(() => {
        // After deleting all reviews and possibly parent keys, delete the user's submissions record
        return remove(submissionsRef);
      })
      .catch((error) => {
        console.error("Error in deletion process: ", error);
        button.disabled = false; // Re-enables the button if the process fails
      });
    }
  });
}

function Profile({user}) {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const userUid = user.currentUser?.uid; // Update this based on your URL parameter
    const userc = user.currentUser
    const [previousSubmissions, setPreviousSubmissions] = useState([]);
    // const [averageTotalScore, setAverageTotalScore] = useState(0);
    const navigate = useNavigate(); // Get the navigate function
    const [deleting, setDeleting] = useState(false);
    // const database = getDatabase();
    const [submissionKeys, setSubmissionKeys] = useState([]);

    useEffect(() => {
      const submissionsRef = ref(database, `Users/${userUid}/Submissions`);
      
      onValue(submissionsRef, (snapshot) => {
        if (snapshot.exists()) {
          const submissionsData = snapshot.val();
          const combinedDataPromises = Object.entries(submissionsData).map(([submissionKey, { parentKey, reviewId }]) => {
            return get(ref(database, `Review/${parentKey}/${reviewId}`)).then((reviewSnap) => {
              if (reviewSnap.exists()) {
                return {
                  ...reviewSnap.val(), // Spread review details
                  submissionKey, // Add submissionKey for easier deletion handling
                  reviewId, // Include reviewId in case it's not part of the review details
                  parentKey, // Include parentKey for deletion logic
                };
              }
              return null;
            });
          });
    
          Promise.all(combinedDataPromises).then((reviews) => {
            const filteredReviews = reviews.filter((review) => review !== null); // Remove any null entries
            setPreviousSubmissions(filteredReviews); // Update state with combined review data
          });
        }
      });
    }, [userUid, previousSubmissions]);

    function handleDeleteAccount() {
      setDeleting(true);
      deleteAccount({user}, {setDeleting}, database);
      deleteAccountAuth({user}, {navigate}, {setDeleting});
    }

    return (
      <>
        {deleting ? <h2>Deleting account...</h2> :
        <>
        <div className='review-main'>
            <h2>Reviews</h2>
            <ul className='card-container'>
            {previousSubmissions.map((review, index) => (
                  <ReviewCard
                    key={index}
                    reviewId={review.reviewId}
                    parentKey={review.parentKey}
                    onDelete={() => handleDeleteReview(review.reviewId, review.parentKey, userUid)} // Pass userUid here
                  >
                  <ul>
                    <li>
                      <p> <span className='descriptor'>Tenancy:</span> <span className='info'>{review.personalInfo.startdate} - {review.personalInfo.enddate}, {review.daysDifference} days</span></p>
                    </li>
                    <li>
                      <p> <span className='descriptor'>Address:</span> <span className='info'>{`${review.personalInfo.houseNumber}, ${review.personalInfo.road}, ${review.personalInfo.city}`} </span></p>
                    </li>
                    {/* Assuming you add a delete button within ReviewCard itself as per previous discussion */}
                    {/* Otherwise, add a button here and handle deletion */}
                  </ul>
                </ReviewCard>
            ))}
            </ul>
        </div>
        <div>
            {user.currentUser?.emailVerified ? null : <VerifyEmailMsg user={user}/>}
            <h2>Delete Account?</h2>
            <button id="deleteButton" onClick={handleDeleteAccount}>Delete Account</button>
        </div>
        </>
        }
      </>
    );
  }
    
  
  
export default Profile;
