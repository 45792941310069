import React from 'react';

import BuyButtonComponent from './BuyButtonComponent';

function PaymentForm() {
  return (
    <BuyButtonComponent/>
  );
};
export default PaymentForm;
