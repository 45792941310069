import Map from '../Components/Map';

import React, { useState, useEffect } from 'react';
// import firebase from 'firebase/compat/app';
import LoadingIndicator from '../Components/LoadingIndicator';
import VerifyEmailMsg from '../Components/VerifyEmailMsg';
import { useNavigate } from 'react-router-dom';

export default function Home({user}) {
  const navigate = useNavigate();
  const countryCoordinates = {
    "United States": [37.0902, -95.7129],
    "United Kingdom": [51.509865, -0.118092],
    // Add more countries as needed
  };
  const [position, setPosition] = useState([51.509865, -0.118092]);
  // const [loading, setLoading] = useState(true); // Add a loading state

  useEffect(() => {
    setPosition(countryCoordinates["United Kingdom"]);
    console.log("United Kingdom", position);
  }, []);

  useEffect(() => {
    if(!user.currentUser){
      navigate("/login")
    }
  },[user.currentUser]);

  return (
    <>
      {user.currentUser ? 
        (
          // Render a loading indicator while waiting for user authentication
          <>
            {user.currentUser?.emailVerified ? 
            // Render the Map component once loading is complete
              <Map userCountry={position} userEmail={user.currentUser?.email}/>
              :
              <VerifyEmailMsg user = {user}/>  
            }
          </>
        ) : (
          <LoadingIndicator />
        )
      }
    </>
  );
}
