import React, { useState, useEffect, useRef } from 'react';
import { database } from "../FirebaseConfig";
import {ref, push, child, onValue, off, get, set} from "firebase/database";
import { getStorage, ref as refs, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { useAuthState } from "react-firebase-hooks/auth";
import firebase from 'firebase/compat/app';

import './Form.css';

import axios from 'axios';

import { useLocation, Link, useNavigate } from 'react-router-dom';
import zipCodeFindAddress from '../Components/ZipCodeFindAddress';

function CardComponent({ children, description, defaultExpanded=false, isComplete}) {
  const [expanded, setExpanded] = useState(defaultExpanded);
  return (
    <div className={`card ${expanded ? 'expanded' : ''}`}>
      <div className={`card-header ${isComplete ? 'all-selected' : ''}`} onClick={() => setExpanded(!expanded)}>
        <h2 className="card-title">{description}</h2>
      </div>
      <div className="card-body">
        {children}
      </div>
    </div>
  );
}

const Tooltip = ({ message }) => {
  const [isVisible, setIsVisible] = useState(false);

  const showTooltip = () => setIsVisible(true);
  const hideTooltip = () => setIsVisible(false);

  return (
    <div className="tooltip-container" onMouseEnter={showTooltip} onMouseLeave={hideTooltip}>
      <span className="tooltip-icon">?</span>
      {isVisible && (
        <div className="tooltip-box">
          {message}
        </div>
      )}
    </div>
  );
};

// function AutoComplete ({setFormData, formData}) {
//   const [suggestions, setSuggestions] = useState([]);
//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData((prevFormData) => ({
//       ...prevFormData,
//       [name]: value,
//     }));
    
//     // Call the backend to fetch autocomplete suggestions
//     // console.log(value);
//     if (value.length > 2){
//       fetchSuggestions(value);
//     }
//     console.log(name, formData[name] = value);
//   };

//   const fetchSuggestions = async (input) => {
//     try {
//       const response = await axios.get(`https://europe-west1-rent-1111.cloudfunctions.net/autocomplete?input=${encodeURIComponent(input)}`);
//       const suggestionsData = response.data;
//       setSuggestions(suggestionsData.suggestions);
//     } catch (error) {
//       console.error('Error fetching autocomplete suggestions:', error);
//     }
//   }

//   return (
//   <>
//   <label className="name">Address*</label>
//     <input
//       type="text"
//       id="address"
//       name="address"
//       className="address-input"
//       value={formData.address || ""}
//       onChange={handleChange}
//       list="address-suggestions"
//       required
//     />
//     <datalist id="address-suggestions">
//       {suggestions.map((suggestion, index) => (
//         <option key={index} value={suggestion} />
//       ))}
//     </datalist>
//   </>
//   );
// };


function RadioFormWithScore({
  formData,
  handleChangeWithScore,
  selections,
  structure,
  description,
  tooltipMessage,
  includeNA = false // This prop controls the inclusion of "N/A"
}) {
  const [selectedOption, setSelectedOption] = useState(formData[structure].text);
  const [isNA, setIsNA] = useState(formData[structure].isNA || false);

  useEffect(() => {
    setSelectedOption(formData[structure].text);
    setIsNA(formData[structure].isNA || false);
    console.log(`${structure} isNA state:`, isNA); // Debugging state changes
  }, [formData[structure], isNA]); // Monitor isNA for changes as well

  const handleNAChange = (event) => {
    const checked = event.target.checked;
    console.log(`${structure} Toggle Changed:`, checked); // Debug statement
    setIsNA(checked);
    if (checked) {
      // If N/A is checked, reset the selected option and potentially the score
      handleChangeWithScore({ target: { name: structure, value: "N/A", isNA: true } }, 0, selections.length, true);
    } else {
      // If N/A is unchecked, ensure the form data reflects that it's not applicable
      handleChangeWithScore({ target: { name: structure, value: "", isNA: false } }, 0, selections.length, false);
    }
  };

  return (
    <>
      <div className={`radio-box-container ${selectedOption || isNA ? 'selected' : ''} ${isNA ? 'completed' : ''}`}>
        <div className={`radio-box-header ${isNA ? 'completed' : ''}`}>
          <label className={structure}>{description}</label>
          {tooltipMessage && <Tooltip message={tooltipMessage} />}
          {includeNA && (
            <div className="na-toggle">
              <label className="switch">
                <input
                  type="checkbox"
                  id={`${structure}-na`}
                  checked={isNA}
                  onChange={handleNAChange}
                />
                <span className="slider round"></span>
              </label>
              <label htmlFor={`${structure}-na`} className="na-label">N/A</label>
            </div>
          )}
        </div>
        <div className={`radio-box ${selectedOption || isNA ? 'selected' : ''} ${isNA ? 'disabled' : ''}`}>
          <ul className="horizontal-list">
            {selections.map((selection, index) => (
              <li key={selection} className="radio-list">
                <input
                  type="radio"
                  id={`${structure}-${selection}`}
                  name={structure}
                  value={selection}
                  onChange={(event) => !isNA && handleChangeWithScore(event, index + 1, selections.length)}
                  checked={!isNA && formData[structure].text === selection}
                  className="radio-input"
                  disabled={isNA}
                />
                <label htmlFor={`${structure}-${selection}`} className="radio-label">{selection}</label>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
}


export default function Form() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const navigate = useNavigate();

  // Extract individual query parameters
  const address = queryParams.get("fullAddress");
  const houseNumber = queryParams.get("house_number");
  const road = queryParams.get("road");
  const city = queryParams.get("city");
  const state = queryParams.get("state");
  const postCode = queryParams.get("postcode");
  console.log(queryParams);
  console.log(address, houseNumber, road, city, state, postCode);
  const [user, loading] = useAuthState(firebase.auth());

  useEffect(() => {
    if(!user){
      navigate("/login")
    }
  },[user]);

  const [markerData, setMarkerData] = useState({
    latitude: 0,
    longitude: 0,
    address: address || '',
    houseNumber: houseNumber || '', // House name or number
    road: road || '', // Street address
    city: city || '', // City
    state: state || '', // State or province
    postcode: postCode || '', // Postal code or ZIP code
  });
  const [formData, setFormData] = useState({
    personalInfo: { //adding this to check if every data can be checked without listing everything
      name: loading ? '' : user?.displayName,
      email: loading ? '' : user?.email,
      flat: "",
      houseNumber: houseNumber || '', // House name or number
      road: road || '', // Street address
      city: city || '', // City
      state: state || '', // State or province
      postcode: postCode || '',
      startdate: "",
      enddate: "",
    },
    daysDifference: "",
    user: loading ? '' : user?.uid,
    landlord: {text: "", score: 0},
    agency: {text: "", score: 0},
    contract: {text: "", score: 0},
    utilitiesBill: {text: "", score: 0},
    structuralIntegrity: {text: "", score: 0},
    adjacentSoundproofing: {text: "", score: 0},
    verticleSoundproofing: {text: "", score: 0},
    windowSoundproofing: {text: "", score: 0},
    apartmentNoise: {text: "", score: 0},
    bed: {text: "", score: 0},
    ac: {text: "", score: 0},
    heating: {text: "", score: 0},
    dishwasher: {text: "", score: 0},
    microwave: {text: "", score: 0},
    oven: {text: "", score: 0},
    stovetop: {text: "", score: 0},
    refrigerator: {text: "", score: 0},
    safety: {text: "", score: 0},
    cityConnection: {text: "", score: 0},
    recreationalArea: {text: "", score: 0},
    streetParking: {text: "", score: 0},
    bbq: {text: "", score: 0},
    garden: {text: "", score: 0},
    parking: {text: "", score: 0},
    totalscore: 0,
    file: "",
    comments: "",
    verifiedlevel: 0

    
  

  });

  const [sectionCompletion, setSectionCompletion] = useState({
    personalInfoComplete: false,
    amenitiesComplete: false,
    areaInformationComplete: false,
    leaseInsightsComplete: false,
    interiorQualityComplete:false,
    commentComplete:false
  });

  
  useEffect(() => {
    const amenitiesComplete = ['bed', 'ac', 'heating', 'dishwasher', 'microwave', 'oven', 'stovetop', 'refrigerator']
      .every(key => formData[key]?.text !== "");
  
    const areaInformationComplete = ['safety', 'cityConnection', 'recreationalArea', 'streetParking']
      .every(key => formData[key]?.text !== "");

    const leaseInsightsComplete = ['landlord', 'agency', 'contract','utilitiesBill' ]
      .every(key => formData[key]?.text !== "");
  
    const interiorQualityComplete = ['structuralIntegrity', 'adjacentSoundproofing', 'verticleSoundproofing', 'windowSoundproofing', 'apartmentNoise']
      .every(key => formData[key]?.text !== "");

    const commentComplete = formData.comments?.trim() !== "";

    const personalInfoComplete = calculatePersonalInfoComplete();
  
    setSectionCompletion(prev => ({
      ...prev,
      personalInfoComplete,
      amenitiesComplete,
      areaInformationComplete,
      interiorQualityComplete,
      leaseInsightsComplete,
      commentComplete
    }));
    console.log(sectionCompletion)
  }, [formData], sectionCompletion);
  
  const calculatePersonalInfoComplete = () => {
    console.log('THIS IS CHECKING')
    return Object.entries(formData.personalInfo).every(([key, value]) => {
      const isComplete = typeof value === 'string' ? value.trim() !== "" : true;
      if (!isComplete) console.log(`${key} is incomplete: ${value}`);
      return isComplete;
    });
  };
  
  
//const [selectedFile, setSelectedFile] = useState(null);
//////////////////////////////////////////////////////////////////////////////////////////
/*
const handleFileChange = (event) => {
  const file = event.target.files[0];
  const maxSize = 5 * 1024 * 1024; // 5MB in bytes

  if (file.size > maxSize) {
    alert("File is too large, please select a file less than 5MB.");
    return;
  }

  setSelectedFile(file);
}
*/

// console.log(formData["Garden"].text)

      const [previousSubmissions, setPreviousSubmissions] = useState([]);
//////////////////////////////////////////////////////////////////////////////////////////
      const handleChange = (event) => {
        const { name, value } = event.target;
        if (name.includes('.')) { // For nested personalInfo fields
          const [parentKey, childKey] = name.split('.');
          setFormData(prev => ({
            ...prev,
            [parentKey]: {
              ...prev[parentKey],
              [childKey]: value
            }
          }));
        } else { // For root level fields
          setFormData(prev => ({
            ...prev,
            [name]: value
          }));
        }
      };
//////////////////////////////////////////////////////////////////////////////////////////
    const handleChangeWithScore = (event, index, total, isNA = false) => {
      const { name, value } = event.target;

      let updatedValue = {
        text: value,
        score: isNA ? null : index * 120 / total, 
        isNA, 
      };

      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: updatedValue,
      }));
    };
//////////////////////////////////////////////////////////////////////////////////////////      
    // Load previous form submissions on component mount
    useEffect(() => {
      if (user) {
        const submissionsRef = ref(database, `Users/${user.uid}/Submissions`);
        const previousSubmissions = [];

        const handleData = (snapshot) => {
          snapshot.forEach((childSnapshot) => {
            // console.log(childSnapshot.val());
            const submissionId = childSnapshot.val();
            console.log(`submissionId`, submissionId)
    //fix this
            // Fetch the corresponding form data from the "Review" collection
            //const formRef = ref(database, `Review/${formData.longitude}A${formData.latitude}/${submissionId}`);
            const formRef = ref(database, `Review/${submissionId.id}`);
            get(formRef).then((formSnapshot) => {
              const formData = formSnapshot.val();
              if (formData) {
                previousSubmissions.push(formData);
              }
              setPreviousSubmissions(previousSubmissions);
            });
          });
        };

    // Subscribe to the data
    onValue(submissionsRef, handleData);

    // Unsubscribe when component unmounts
    return () => off(submissionsRef, handleData);
  }
}, [user]);
      const handleLoadSubmission = (submission) => {
        setFormData(submission);
      };
      
      const addressRef = useRef(null);
      const autocompleteRef = useRef(null);
    
      useEffect(() => {
        if (!loading) {
          setFormData((prevFormData) => ({
            ...prevFormData,
            user: user?.uid,
            name: user?.displayName,
            email: user?.email,
          }));
        }
      }, [user, loading]);


      const handleKeyDown = (event) => {
              if (event.key === "Enter") {
                event.preventDefault();
              }
            };
    const [submitted, setSubmitted] = useState(false);
    const [submittedInfo, setSubmittedInfo] = useState([]);

    const calculateTotalScore = () => {
      const scoreFields = [
        'landlord', 'agency', 'utilitiesBill', 'contract',
        'structuralIntegrity', 'adjacentSoundproofing', 'verticleSoundproofing',
        'windowSoundproofing', 'apartmentNoise', 'bed', 'ac', 'heating',
        'dishwasher', 'microwave', 'oven', 'stovetop', 'refrigerator',
        'safety', 'cityConnection', 'recreationalArea', 'streetParking',
      ];
      
      let totalScore = 0;
      let count = 0;
      scoreFields.forEach(field => {
        if (formData[field] && formData[field].score !== null && !formData[field].isNA) {
          totalScore += formData[field].score;
          count++;
        }
      });
    
      return count > 0 ? totalScore / count : 0;
    };
    
    
    const submissionAddress = markerData.houseNumber + " " + markerData.road + " " + markerData.city + " " ;
    // Call the function with the address



    function calculateDaysDifference(startdate, enddate) {
      const startDate = new Date(startdate);
      const endDate = new Date(enddate);
      const timeDifference = endDate.getTime() - startDate.getTime();
      return Math.ceil(timeDifference / (1000 * 3600 * 24));
    }
    
    const fetchGeolocationData = (submissionAddress) => {
      return new Promise(async (resolve, reject) => {
        try {
          const encodedAddress = encodeURIComponent(submissionAddress);
          const response = await axios.get(`https://nominatim.openstreetmap.org/search?q=${encodedAddress}&format=json`);
          if (response.data && response.data.length > 0) {
            resolve({
              latitude: response.data[0].lat,
              longitude: response.data[0].lon,
            });
          } else {
            reject('No data found for the specified address.');
          }
        } catch (error) {
          reject('Error fetching data:', error);
        }
      });
    };


    const dbRef = ref(database); 
    const storage = getStorage();
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const handleSubmit = async (event) => {
  event.preventDefault();

  if (!user || loading) {
    alert('User not loaded or not signed in');
    return;
  }

  try {
    // Ensure user ID is available
    const userId = user.uid;
    if (!userId) {
      console.error('No user ID available');
      return;
    }

    // Calculate total score
    const totalScore = calculateTotalScore();

    // Validate daysDifference
    const daysDifference = calculateDaysDifference(formData.personalInfo.startdate, formData.personalInfo.enddate);
    if (daysDifference < 60) {
      alert("The difference between the start date and end date is less than two months.");
      return;
    }

    // Fetch geolocation data
    const submissionAddress = `${formData.personalInfo.houseNumber} ${formData.personalInfo.road} ${formData.personalInfo.city} `;
    const geolocationData = await fetchGeolocationData(submissionAddress);

    // Prepare submission data with user ID
    let submissionData = {
      ...formData,
      totalscore: totalScore,
      daysDifference: daysDifference,
      latitude: geolocationData.latitude,
      longitude: geolocationData.longitude,
      user: userId, // Include user ID in submission data
    };

    // Push submission data to Firebase
    console.log('Geolocation Data:', geolocationData);
    const cleanedLongitude = geolocationData.longitude.toString().replace(".", "_").replace("-", "m");
    const cleanedLatitude = geolocationData.latitude.toString().replace(".", "_").replace("-", "m");
    const reviewRef = ref(database, `Review/${cleanedLongitude}A${cleanedLatitude}`);
    const newReviewRef = await push(reviewRef, submissionData);
    console.log(userId)

    if (newReviewRef.key) {
      // Update user submissions
      const userSubmissionsRef = ref(database, `Users/${userId}/Submissions`);
      const newSubmissionRef = await push(userSubmissionsRef, {reviewId: newReviewRef.key, parentKey: `${cleanedLongitude}A${cleanedLatitude}`});

      setSubmitted(true);
      setSubmittedInfo([submissionData, geolocationData]);
    }
  } catch (error) {
    console.error("Error during form submission:", error);
  }



      // const updates = {};
      // updates['/Review'] = formData;
      // update(ref(database), updates);     
      // setMarkerData({latitude: 0, longitude: 0, address: ""});
      setFormData({
        personalInfo: {
        houseNumber: '', // House name or number
        road: '', // Street address
        city: '', // City
        state: '', // State or province
        postcode: '', // Postal code or ZIP code
        startdate: "",
        enddate: "",
        },
        daysDifference: "",
        landlord: {text: "", score: 0},
        agency: {text: "", score: 0},
        utilitiesBill: {text: "", score: 0},
        contract: {text: "", score: 0},
        structuralIntegrity: {text: "", score: 0},
        adjacentSoundproofing: {text: "", score: 0},
        verticleSoundproofing: {text: "", score: 0},
        windowSoundproofing: {text: "", score: 0},
        apartmentNoise: {text: "", score: 0},
        bed: {text: "", score: 0},
        ac: {text: "", score: 0},
        heating: {text: "", score: 0},
        dishwasher: {text: "", score: 0},
        microwave: {text: "", score: 0},
        oven: {text: "", score: 0},
        stovetop: {text: "", score: 0},
        refrigerator: {text: "", score: 0},
        safety: {text: "", score: 0},
        cityConnection: {text: "", score: 0},
        recreationalArea: {text: "", score: 0}, // Ensure to adjust or merge if needed, as it was mentioned twice previously
        streetParking: {text: "", score: 0},
        bbq: {text: "", score: 0},
        garden: {text: "", score: 0},
        parking: {text: "", score: 0},
        totalscore: 0,
        file: "",
        comments: "",
        verifiedlevel: 0,
      });

    }
      
      
    const [selectedSubmission, setSelectedSubmission] = useState("");
    const maxLength = 500;
    const remainingChars = formData.comments ? maxLength - formData.comments.length : maxLength;

    // const navigate = useNavigate();
  
    // const handleReload = () => {
    //   navigate('/Profile');
    // }

    const handleAddressLookup = () => {
      zipCodeFindAddress(formData.postcode, setFormData);
    };
      console.log("submittedInfo", submittedInfo);
      console.log("submittedInfo", submittedInfo[1]);
    return (
      <div className="form-container">
        {!user ? (
        <div className='user-alert'>
          Please sign in to submit a form.
        </div>
      ) : (
      <form onSubmit={handleSubmit} onKeyDown={handleKeyDown} className="form">
        {/* <button onClick={handleReload}>Reload Page</button> */}
        <div className="review-text-container">

          <h1>Review your renting experience</h1>
          <p>Share your experience with us!</p>
          <p>Fields marked with * are required.</p>
          <p>If your form doesnt submit please double check your address details.</p>
          <p>Thank you for your time!</p>
          <p> </p>
      
        </div>
        <ul className="list-container">
        <CardComponent description="Personal Info" defaultExpanded={true} isComplete={sectionCompletion.personalInfoComplete}>
          <div className="basic-form-container">
            <div className="basic-form-container-name-email">
              <div className="name-box">
                <label className="name">Name*</label>
                <input 
                  type="text" 
                  name="personalInfo.name" 
                  className="name-input" 
                  value={formData.personalInfo.name || ""} 
                  onChange={handleChange} 
                  required 
                />
              </div>
              <div className="name-box">
                <label className="name">Email*</label>
                <input 
                  type="email" 
                  name="personalInfo.email" 
                  className="email-input" 
                  value={formData.personalInfo.email || ""} 
                  onChange={handleChange} 
                  required 
                />
              </div>
            </div>
            <div className="address-container">
              <label className="name">Post Code/ZIP Code*</label>
              <input 
                type="text" 
                name="personalInfo.postcode" 
                className="address-input" 
                value={formData.personalInfo.postcode || ""} 
                onChange={handleChange} 
                required 
              />
              <button type="button" onClick={handleAddressLookup}>Lookup Address</button>
              <label className='name'>State*</label>
              <input 
                type="text" 
                name="personalInfo.state" 
                className="address-input" 
                value={formData.personalInfo.state || ""} 
                onChange={handleChange} 
                required 
              />
              <label className="name">City*</label>
              <input 
                type="text" 
                name="personalInfo.city" 
                className="address-input" 
                value={formData.personalInfo.city || ""} 
                onChange={handleChange} 
                required 
              />
              <label className="name">Street Address*</label>
              <input 
                type="text" 
                name="personalInfo.road" 
                className="address-input" 
                value={formData.personalInfo.road || ""} 
                onChange={handleChange} 
                required 
              />
              <label className="name">Building/House Number*</label>
              <input 
                type="text" 
                name="personalInfo.houseNumber" 
                className="address-input" 
                value={formData.personalInfo?.houseNumber || ""} 
                onChange={handleChange} 
                required 
              />
            <div className="name-box">
              <div className="label-and-tooltip">
                <span className="label">Flat</span> {/* Ensure the label class is applied here */}
                <Tooltip message="Your flat number or name, if applicable." />
              </div>
              <input 
                type="text" 
                name="personalInfo.flat" 
                className="address-input" /* This class controls the width of the input */
                value={formData.personalInfo.flat || ""} 
                onChange={handleChange} 
              />
            </div>
            </div>
            <div className="date-container">
              <div className="date-start-container-box">
                <label className="date-name">Start Date*</label>
                <input 
                  type="date" 
                  name="personalInfo.startdate" 
                  value={formData.personalInfo.startdate || ""} 
                  onChange={handleChange} 
                  required 
                />
              </div>
              <div className="date-end-container-box">
                <label className="date-name">End Date*</label>
                <input 
                  type="date" 
                  name="personalInfo.enddate" 
                  value={formData.personalInfo.enddate || ""} 
                  onChange={handleChange} 
                  min={formData.personalInfo.startdate} 
                  required 
                />
              </div>
            </div>
          </div>
        </CardComponent>

        <CardComponent description="Lease Insights" defaultExpanded={true} isComplete={sectionCompletion.leaseInsightsComplete}>
        
        <RadioFormWithScore formData={formData} handleChangeWithScore={handleChangeWithScore} includeNA={true} selections={["Negligent", "Unhelpful", "Average", "Good", "Excellent"]} structure="landlord" description="LandLord" tooltipMessage="How responsive, kind, and accomodating was your landlord?"/>

        <RadioFormWithScore formData={formData} handleChangeWithScore={handleChangeWithScore} includeNA={true} selections={["Negligent", "Unhelpful", "Average", "Good", "Excellent"]} structure="agency" description="Agency" tooltipMessage="How effective was your housing agency in finding your home and were you treated fairly in the process?"/>

        <RadioFormWithScore formData={formData} handleChangeWithScore={handleChangeWithScore} includeNA={true} selections={["Unfair", "Fair", "Good"]} structure="contract" description="Contract" tooltipMessage="How reasonable was your contract?"/>

        <RadioFormWithScore formData={formData} handleChangeWithScore={handleChangeWithScore} includeNA={true} selections={["Yes", "No"]} structure="utilitiesBill" description="Utilities Bill" tooltipMessage="Did your monthly rent include the utilites cost for things like heating, water and electricty?"/> 
        
        {/*need to include a slider for Utilites and monthly payments*/}
        </CardComponent>

        <CardComponent description="Interior Quality" defaultExpanded={true} isComplete={sectionCompletion.interiorQualityComplete}>

        <RadioFormWithScore formData={formData} handleChangeWithScore={handleChangeWithScore} selections={["Falling apart", "Needs work", "Average", "Good", "Excellent"]} structure="structuralIntegrity" description="Structural Integrity" tooltipMessage="How were the conditions of the wall paint, furrniture, bedframe?"/>

        <RadioFormWithScore formData={formData} handleChangeWithScore={handleChangeWithScore} selections={["Thin", "Average", "Good", "Excellent"]} structure="adjacentSoundproofing" description="Adjacent Soundproofing" tooltipMessage="Were the walls thick enough to deaden noise from nextdoor neighbors?"/>
        
        <RadioFormWithScore formData={formData} handleChangeWithScore={handleChangeWithScore} selections={["Noisy", "Average", "Good", "Excellent"]} structure="verticleSoundproofing" description="Verticle Soundproofing" tooltipMessage="How sensative were the floors and ceilings to movement?"/>

        <RadioFormWithScore formData={formData} handleChangeWithScore={handleChangeWithScore} selections={["Noisy", "Average", "Good", "Excellent"]} structure="windowSoundproofing" description="Window Soundproofing" tooltipMessage="How well did your windows reduce noise from your exterior enviornment?"/>
        
        <RadioFormWithScore formData={formData} handleChangeWithScore={handleChangeWithScore} selections={["Noisy", "Average", "Good", "Excellent"]} structure="apartmentNoise" description="Apartment Noise" tooltipMessage="How was the overall noise inside affected by things like neighbors or home appliances?"/>
        
        </CardComponent>

        <CardComponent description="Amenities" defaultExpanded={true} isComplete={sectionCompletion.amenitiesComplete}>

        <RadioFormWithScore formData={formData} handleChangeWithScore={handleChangeWithScore} selections={["No", "Yes"]} structure="bed" description="Bed"/>

        <RadioFormWithScore formData={formData} handleChangeWithScore={handleChangeWithScore} selections={["No", "Yes"]} structure="ac" description="Air Conditioning"/>

        <RadioFormWithScore formData={formData} handleChangeWithScore={handleChangeWithScore} selections={["No", "Yes"]}structure="heating" description="Heating"/>

        <RadioFormWithScore formData={formData} handleChangeWithScore={handleChangeWithScore} selections={["No", "Yes"]} structure="dishwasher" description="Dishwasher"/>

        <RadioFormWithScore formData={formData} handleChangeWithScore={handleChangeWithScore} selections={["No", "Yes"]} structure="microwave" description="Microwave"/>

        <RadioFormWithScore formData={formData} handleChangeWithScore={handleChangeWithScore} selections={["No", "Yes"]} structure="oven" description="Oven"/>

        <RadioFormWithScore formData={formData} handleChangeWithScore={handleChangeWithScore} selections={["No", "Yes"]} structure="stovetop" description="Stove"/>

        <RadioFormWithScore formData={formData} handleChangeWithScore={handleChangeWithScore} selections={["No", "Yes"]} structure="refrigerator" description="Refrigerator"/>

        </CardComponent>


        <CardComponent description="Area Information" defaultExpanded={true} isComplete={sectionCompletion.areaInformationComplete}>

        <RadioFormWithScore formData={formData} handleChangeWithScore={handleChangeWithScore} selections={["Unsafe", "Average", "Good", "Excellent"]} structure="safety" description="Safety" tooltipMessage="How safe does the enviornment feel near your living space?"/>

        <RadioFormWithScore formData={formData} handleChangeWithScore={handleChangeWithScore} selections={["Difficult", "Average", "Good", "Excellent"]} structure="cityConnection" description="City Accessibilitiy" tooltipMessage="How accesible is the city from your space?"/>

        <RadioFormWithScore formData={formData} handleChangeWithScore={handleChangeWithScore} selections={["Poor", "Average", "Good", "Excellent"]} structure="recreationalArea" description="Recreational Area" tooltipMessage="Does the surrounding area have a diverse assortment of acrvities to explore?"/>

        <RadioFormWithScore formData={formData} handleChangeWithScore={handleChangeWithScore}  includeNA={true} selections={["Impossible", "Unlikely", "Average", "Likely", "Easily"]} structure="streetParking" description="Street Parking" tooltipMessage="How easy is it for one to consistency park their car safely in the surrounding area?"/>

        {/*need to include a slider for age range*/}
        </CardComponent> 
        
        <CardComponent description= "Comment" defaultExpanded={true} isComplete={sectionCompletion.commentComplete}> 
        <div className="additional-info-container">
          <textarea
            id="comments"
            name="comments"
            rows="10"
            cols="50"
            maxLength={maxLength}
            placeholder="Additional Information"
            value={formData.comments || ""}
            onChange={handleChange}
          /><div className='character-counter'>{remainingChars}</div>
        </div>
        </CardComponent>
        </ul>
        
        <div className="submit-button-container">
          <input type="submit" value="Submit" className="submit-button"/>
        </div>
        {/* Conditionally render the card with the link to reviews after form submission */}
      {submitted && (
        <div className="submit-message">
          <div className="submit-message-container">
          <h1>Thank you for your review!</h1>
          </div>
          <div className="submit-message-container">
            <p>Your review has been submitted successfully. </p>
            <p>
            <Link
              to={{
                pathname: "/Reviews",
                search: `?address=${encodeURIComponent(submittedInfo[0].personalInfo.houseNumber + " " + submittedInfo[0].personalInfo.road + ", " + submittedInfo[0].personalInfo.city, submittedInfo[0].personalInfo.postcode)}&latitude=${submittedInfo[0].latitude}&longitude=${submittedInfo[0].longitude}`,
              }}
              className="nav-link"
            >Click here to see your reviews</Link>
            </p>
            </div>
            <button onClick={() => setSubmitted(false)} className='submit-message-close'>Close</button>
        </div>
      )}
      </form>
      )}
      </div>
    );
}