import * as React from 'react';

function BuyButtonComponent() {
  const pk = "pk_test_51NQ4XlIxB4l3tN1Z87tuTxLlVNu8JRBypV4rdiYpxo9s7EnIQ0a2iO3IwoHStaJNp32GHqLDSjHcAw8htMGgAY6F00XqOwPYyD"
  // const pk = "pk_live_51NQ4XlIxB4l3tN1ZFtGfT53stdpr0dmChtqafurvZeHOBNaKsV4P3lx8j1dpcawU9R5h87DYMyLTQS8508fl8dEY00VSOpIG0T"
  // const buyButtonId = "buy_btn_1O5AfKIxB4l3tN1ZvcJg3OSP"
  const buyButtonId = "buy_btn_1O5BGsIxB4l3tN1Zg0YhbGLT"
  return (
    <stripe-buy-button
      buy-button-id={buyButtonId}
      publishable-key={pk}
    >
    </stripe-buy-button>
  );
}

export default BuyButtonComponent;