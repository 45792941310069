import React from 'react';
// import firebase from 'firebase/compat/app';
import {useNavigate} from 'react-router-dom';

export default function SignOut({user}){
  const navigate = useNavigate();
  const handleSignOut = () => {
    user.signOut()
      .then(() => {
        // Sign-out successful.
        console.log('User signed out');
        navigate('/login');
      })
      .catch((error) => {
        // An error happened.
        console.error('Error signing out:', error);
      });
  };
    return user.currentUser && (
      <button className="btn btn-dark" onClick={handleSignOut}>Sign Out</button>
    )
}