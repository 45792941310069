import React, { useState, useEffect } from 'react';
import firebase from 'firebase/compat/app';
import googleLogo from './img/google-logo.png';
import './SignInWithGoogle.css';
import { useNavigate } from 'react-router-dom';

async function isEmailRegistered({user}, {email}) {
  try {
    const userRecord = await user.getUserByEmail(email);
    return userRecord;
  } catch (error) {
    // Handle errors, such as user not found
    console.error('Error checking email registration:', error);
    return false;
  }
}

export default function SignInWithGoogle({ user }) {
    // Link Google account to existing email/password account   
    const navigate = useNavigate();
    
    const provider = new firebase.auth.GoogleAuthProvider();
    
  const handleSignInWithGoogle = async () => {
    const result = await user.signInWithPopup(provider);
    if(result){
      console.log("User signed in with Google");
      navigate("/");
    }else{
      console.log("Signin with google");
    }
  };
  return (
    <div>
      {user.currentUser? "Logging In":
        <button className="google-sign-in-button" onClick={handleSignInWithGoogle}>
          <div className="google-logo">
            <img src={googleLogo} alt="Google Logo" className="google-logo-image" />
          </div>
          Sign In with Google
        </button>
      }
    </div>
  );
}
