export default function zipCodeFindAddress(zipCode, setFormData) {
  console.log("zipCode", zipCode);
  // Make an API request to fetch address details
  fetch(`https://nominatim.openstreetmap.org/search?postalcode=${zipCode}&format=json&addressdetails=1`)
    .then(response => response.json())
    .then(data => {
      if (data.length > 0) {
        console.log(data);
        const firstResult = data[0];

        // Check if city and state components exist in the address
        const addressComponents = firstResult.address;

        if (addressComponents) {
          const city = addressComponents.city || addressComponents.village;
          const state = addressComponents.state || addressComponents.county;

          if (city && state) {
            setFormData((prevFormData) => {
              return {
                ...prevFormData,
                city: city,
                state: state,
              };
            });
            // console.log(`City: ${city}`);
            // console.log(`State: ${state}`);
            // Update your form or display with the retrieved address details
          } else {
            console.error('City or State not found in address data');
          }
        } else {
          console.error('Address components not found in data');
        }
      } else {
        console.error('No address details found for the provided ZIP code');
      }
    })
    .catch(error => {
      console.error('Error fetching address:', error);
    });
}
