import React, { useEffect } from 'react';
import './App.css';
import './pages/Home.css';

import Home from './pages/Home.jsx';
import Reviews from './pages/Reviews';
import ProfilePage from './pages/Profile.jsx';
import Yay from './pages/Yay.jsx';
import Login from './pages/Login.jsx';

import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';

import { useAuthState } from 'react-firebase-hooks/auth';
// import { auth } from './FirebaseConfig';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';

import Navbar  from './Navbar/Navbar.jsx';
import Form from './Routes/Form.jsx';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

// import ErrorPage from './pages/ErrorPage.jsx';

// const router = createBrowserRouter([
//   { path: '/', element: <Home />, errorElement: <ErrorPage /> },
//   { path: '/Form', element: <Form />, errorElement: <ErrorPage /> },
//   { path: '/Reviews', element: <Reviews />, errorElement: <ErrorPage /> },
// ]
// );

export default function App() {
  const user = firebase.auth();//user a object that contains all the information about the user that is currently signed in
  const navigate = useNavigate(); // Get the navigate function

  const query = useQuery();

  useEffect(() => {
    if (user.currentUser) {
      console.log("User is signed in");
      if (!user.currentUser.emailVerified) {
        console.log("User is not verified");
      }
    } else {
      console.log("User is not signed in");
      if (!query){
        navigate('/login');
      }
    }
  }, [user.currentUser, user, navigate]);
  return (
    <>
      <Navbar user = {user}/>
      <Routes>
        <Route path="/" element={<Home user = {user}/>} />
        <Route path="Form" element={<Form />} />
        <Route path="Reviews" element={<Reviews user = {user}/>} />
        <Route path="Profile" element={<ProfilePage user = {user}/>} />
        <Route path="Yay" element={<Yay user = {user}/>} />
        <Route path="Login" element={<Login user = {user}/>} />
      </Routes>
    </>
  );
}