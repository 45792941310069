import React, {useState, useEffect} from "react";
import SignIn from "../Auth/SignIn";
import SignUp from "../Auth/SignUp";
import { useNavigate } from "react-router-dom";
import '../Auth/Sign.css';
import VerifyEmailMsg from "../Components/VerifyEmailMsg";

export default function Login({user}) {
    const [showSignIn, setShowSignIn] = useState(true);
    const [isCardFlipped, setIsCardFlipped] = useState(false);
    const navigate = useNavigate(); // Get the navigate function
    const toggleForm = () => {
        setShowSignIn(!showSignIn);
        console.log(showSignIn);
      };

      const toggleCardFlip = () => {
        setIsCardFlipped(!isCardFlipped);
        console.log( isCardFlipped );
      };

      useEffect(() => {
        // console.log("emailVerified: " + user?.emailVerified);
        if (user.currentUser) {
          navigate("/");
        }
      },[user.currentUser, user, navigate]);

      return(
        <>
        {user.currentUser? <div>{user.currentUser?.verifedEmail ? <div>Verified</div>:<div><VerifyEmailMsg user = {user}/></div>}</div> : 
        <div>
          {showSignIn && <div className="sign-card-flip">
            <div className={`sign-card-flip-inner ${isCardFlipped ? 'flip' : ''}`}>
              <div className='sign-card-flip-back'>
              {/* "Sign Up" form */}
                  <SignUp toggleFormSignUp={toggleForm} toggleCardFlip = {toggleCardFlip} isCardFlipped = {isCardFlipped} user = {user}/>
              </div>
              <div className='sign-card-flip-front'>
              {/* "Sign In" form */}
                  <SignIn toggleFormSignIn={toggleForm} toggleCardFlip = {toggleCardFlip} isCardFlipped = {isCardFlipped} user = {user}/>
              </div>
            </div>
          </div>}
        </div>
        }
        </>
      )
}