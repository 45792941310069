import React, { useState, useEffect } from 'react';
import firebase from 'firebase/compat/app';
import { Button } from 'react-bootstrap';

import SignInWithGoogle from './SignInWithGoogle';
import './Sign.css';

import { useNavigate, useLocation } from 'react-router-dom';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function ResetPassword({oobCode}, {navigate}) {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const handleResetPassword = async (e) => {
    e.preventDefault();

    if (newPassword !== confirmPassword) {
      setError('The passwords do not match.');
      return;
    }

    try {
      await firebase.auth().confirmPasswordReset(oobCode, newPassword);
      setSuccess('Your password has been reset successfully. You can now sign in with your new password.');
      // Redirect user to the sign-in page after a delay or on user acknowledgment
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <div className="reset-password-container">
      <h2>Reset Your Password</h2>
      <form onSubmit={handleResetPassword}>
        <div className="form-group">
          <label htmlFor="newPassword">New Password</label>
          <input
            type="password"
            id="newPassword"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="confirmPassword">Confirm New Password</label>
          <input
            type="password"
            id="confirmPassword"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
        </div>
        <button type="submit">Reset Password</button>
      </form>
      {error && <p className="error-message">{error}</p>}
      {success && <p className="success-message">{success}</p>}
    </div>
  );
}

export default function SignIn({ toggleFormSignIn, toggleCardFlip, isCardFlipped, user }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [verificationEmailSent, setVerificationEmailSent] = useState(false);
  const [currentUser, setCurrentUser] = useState(null); // Store the current user
  const query = useQuery();
  const oobCode = query.get('oobCode');
  console.log(oobCode);
  const navigate = useNavigate(); // Get the navigate function

  // const navigate = useNavigate(); // Get the navigate function

  const auth = user;
  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      await auth.signInWithEmailAndPassword(email, password);
      toggleFormSignIn();
    } catch (error) {
      setError(error.message); // set error message
    }
  };

  const db = firebase.firestore();
  // In your email verification page
  useEffect(() => {
    auth.onAuthStateChanged(async (currentUser) => {
      if (currentUser && !verificationEmailSent) {
        await db.collection('users').doc(currentUser.uid).set({country: "United Kingdom"});
      }
    });
  }, []);

  const handleResetPassword = async () => {
    if (!email) {
      setError("Please enter your email address to reset your password.");
      return;
    }

    try {
      await auth.sendPasswordResetEmail(email);
      alert("Password reset email sent! Please check your inbox.");
    } catch (error) {
      setError(error.message);
    }
  };
  
  return(
    <>
    {oobCode ? (
      <ResetPassword oobCode={oobCode} navigate={navigate} />
    ) : (
    <>
    {toggleFormSignIn &&
    <div className='sign-in-container'>
      <div className='sign-card-body-title'>
        <h2>Sign In</h2>
      </div>
      <div className='sign-card-body-input'>
        {!currentUser && 
        <div>
          <form onSubmit={handleSubmit} className='sign-form'>
            <div className='form-group'>
              <label htmlFor='email' className='sign-form-text'>Email</label>
              <input
                className='form-control'
                type='email'
                name='email'
                id='email'
                onChange={e => setEmail(e.target.value)}
              />
            </div>
            <div className='form-group'>
              <label htmlFor='password' className='sign-form-text'>Password</label>
              <input
                className='form-control'
                type='password'
                name='password'
                id='password'
                onChange={e => setPassword(e.target.value)}
              />
            </div>
            <div className='sign-card-body-input-submit'>
              <Button type='submit'>Sign In</Button>
            </div>
            <button type="button" onClick={handleResetPassword} className="btn btn-link">
              Forgot Password?
            </button>
          </form>
          {error && (
              <div className='alert alert-danger mt-3' role='alert'>
                {error && error.includes("Firebase:") ? error.split("Firebase:")[1].trim() : error}
              </div>            
          )}        
        </div>
        }
        <SignInWithGoogle user={user}/>
        <p className='revert-sign'>
        Don't have an account?{' '}
        { !isCardFlipped ?
        <a type="button" onClick={toggleCardFlip} className='link'>Sign Up</a>
        :
        <a disabled type="button" className='link'>Sign Up</a>
        }
        </p>
      </div>
    </div>}</>)}
  </>
  )
}