import React, { useState, useEffect, useRef } from 'react';
import { MapContainer, TileLayer, useMapEvents, Marker, useMap, Popup } from "react-leaflet";
import { GeoSearchControl, OpenStreetMapProvider } from "leaflet-geosearch";
import { Link } from "react-router-dom";
import L, { map } from 'leaflet';
import 'leaflet.heat/dist/leaflet-heat.js';

import "./Map.css";
// import { set } from 'firebase/database';
import LoadingIndicator from './LoadingIndicator';

function Heatmap({heatmapData}){
  const map = useMap();
  
  useEffect(() => {
    if (map) {
      const heat = L.heatLayer(heatmapData, { radius: 13, max: 0.005, blur: 30 }).addTo(map);
      return () => {
        map.removeLayer(heat);
      };
    }
  }, [map, heatmapData]);
  return null;  
}

function parseCoordinatesArray(inputArray) {
  const coordinates = [];

  inputArray.forEach(input => {
      const parts = input.split('A');
      if (parts.length !== 2) {
          throw new Error('Invalid input format. Expected format: "longitude_latitude"');
      }
      const longitude = parseFloat(parts[0].replace('_', '.').replace('m', '-'));
      const latitude = parseFloat(parts[1].replace('_', '.').replace('m', '-'));

      coordinates.push({ latitude, longitude });
  });

  return coordinates;
}

export default function Map({ userCountry, userEmail }) {
  const [position, setPosition] = useState(userCountry); // Initial position set to [0, 0]
  const enableMarkerZoomLevel = 15;
  const maxZoomLevel = 18;
  const minZoomLevel = 5;
  const zoomLevel = 15;
  const [zoom, setZoom] = useState(zoomLevel);
  const [markerPosition, setMarkerPosition] = useState(null);
  const [address, setAddress] = useState(null);
  const [fullAddress, setFullAddress] = useState(null);
  const [markers, setMarkers] = useState([]);
  const [unParsedData, setUnParsedData] = useState([]);
  const [response, setResponse] = useState(false);
  const [responseMarkerAddress, setResponseMarkerAddress] = useState([false]);
  const mapRef = useRef();
  const [heatMapData, setHeatMapData] = useState([]);
  // const [isPopupOpen, setIsPopupOpen] = useState(false);
  
  useEffect(() => {
    const cloudFunctionUrl = process.env.REACT_APP_FIREBASE_REVIEW_LIST_URL;
    fetch(cloudFunctionUrl).then((response) => response.json()).then((data) => {
      setUnParsedData(data["fileNames"]);
      const parsedData = parseCoordinatesArray(data["fileNames"]);
      console.log(parsedData);
      setMarkers(parsedData);
      setHeatMapData(parsedData.map((parsedData) => [parsedData.latitude, parsedData.longitude, 0.5]));
    }).catch((error) => {
      console.error("Error markers:", error);
    });
  }, []);
  
  
  // useEffect(() => {
  //   const map = mapRef.current;
  //   if (map) {
  //     const bounds = map.getBounds();
  //     const visibleMarkers = markers.filter(marker =>
  //       bounds.contains({ lat: marker.latitude, lng: marker.longitude }, console.log(marker))
  //     );
  //     // setMarkers(visibleMarkers);
  //   }
  // }, [mapRef, markers]);
  
  const customIcon = new L.Icon({
    iconUrl: 'https://oikolex.com/markerIcon.png',
    iconSize: [25, 41], // Set the size of the icon
    iconAnchor: [11.5, 41], // Set the anchor point to the center bottom of the icon
    popupAnchor: [2, -41], // Set the popup anchor to the top of the icon
    shadowUrl: '', // Set an empty string to remove the shadow
  });

  const countryLatLngBounds = calculateBoundsForCountry(userCountry);

  return (
    <div className="map-container">
      {/* Include the Leaflet CSS */}
      <header>
        <link
          rel="stylesheet"
          href="https://unpkg.com/leaflet@1.9.4/dist/leaflet.css"
          integrity="sha256-p4NxAoJBhIIN+hmNHrzRCf9tD/miZyoHS5obTRR9BMY="
          crossOrigin=""
        />
      </header>

      <MapContainer
        center={ position }
        zoom={ zoom }
        scrollWheelZoom={ true }
        whenCreated={(mapInstance) => (mapRef.current = mapInstance)}
        maxBounds={ countryLatLngBounds } // Set the maximum bounds to the user's country bounds
        maxBoundsViscosity={ 1.0 } // Keep the user within the bounds
        minZoom={ minZoomLevel } // Set a maximum zoom level
        maxZoom={ maxZoomLevel } // Set a maximum zoom level
      >
        <SearchBar setMarkerPosition={setMarkerPosition} setAddress={setAddress} zoom={zoom} userEmail={userEmail}/>
        <MarkerLocation setMarkerPosition={setMarkerPosition} setAddress={setAddress} zoom={zoom} enableMarkerZoomLevel={enableMarkerZoomLevel} setResponse = {setResponse} />
        <ZoomEnd setZoom={setZoom} />
        {markers.length >= 1 ?
          <Heatmap heatmapData={heatMapData}/> : null 
        }

        {/* Add the TileLayer component to display the map */}
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {/* Render the Marker components */}
        {markers.length >= 1 && zoom > enableMarkerZoomLevel ? 
        markers.map((marker, index) => (
        <Marker
          eventHandlers={{
            click:
            async () => {
              if(!responseMarkerAddress[index]){
              const responseMarkerAddress = await fetch(process.env.REACT_APP_FIREBASE_REVIEW_COORDS_TO_Address_URL + `?input=${unParsedData[index]}`);
              const data = await responseMarkerAddress.json();
              setMarkers(prevMarkers => {
                const updatedMarkers = [...prevMarkers]; // Create a copy of the markers array
                const dataCleaned = Object.values(data)[0]; // Assuming data contains the object you provided
                const mergedMarker = { ...marker, ...dataCleaned.personalInfo };
                updatedMarkers[index] = mergedMarker; // Update the specific index with the new value
                // console.log(updatedMarkers);
                return updatedMarkers;
              });
              // console.log(markers);
              setResponseMarkerAddress(prevResponse => {
                const updatedResponse = [...prevResponse]
                updatedResponse[index] = true;
                return updatedResponse;
              });
            }},
          }}
          key={index}
          position={
            [marker.latitude, marker.longitude]
          }
          icon={customIcon}>
          <Popup>
            <div>
              <p>{marker.road == null ?  "Loading..." : marker["houseNumber"]} {marker.road} {marker.city} {marker.state} {marker.postcode}</p>
              {marker?.road &&
              <>
              <Link
                to={{
                  pathname: "/Reviews",
                  search: `?address=${encodeURIComponent(marker["houseNumber"]+ " " + marker.road + " " + marker.city + " " + marker.state + " " + marker.postcode)}&latitude=${marker.latitude}&longitude=${marker.longitude}`,
                }}
                className="nav-link"
              >
                Go to reviews
              </Link>
              <Link to={{ pathname: "/Form", search: `?house_number=${
                encodeURIComponent(marker.houseNumber)}&road=${
                  encodeURIComponent(marker.road)}&city=${
                    encodeURIComponent(marker.city)}&state=${
                      encodeURIComponent(marker.state)}&postcode=${
                        encodeURIComponent(marker.postcode)}` }} className="nav-link" 
              >
                Go to form
              </Link></>}
              {/* Include other data properties in the popup as needed */}
            </div>
          </Popup>
        </Marker>
        ))
      : null        
      }
        {/* Render the Marker component if markerPosition is not null */}
        {markerPosition && zoom > enableMarkerZoomLevel &&(
            <Marker icon={customIcon} position={markerPosition}  eventHandlers={{
              click: 
              async () => {
                if(!response){
                const response = await fetch(`https://nominatim.openstreetmap.org/reverse?format=json&lat=${markerPosition[0]}&lon=${markerPosition[1]}&addressdetails=1`);
                const data = await response.json();
                setFullAddress(data.display_name);
                setAddress(data.address||"loading...");
                setResponse(true);
              }},
            }}>
            <Popup autoOpen>
              <div>
                <p>{address?.house_number&& address?.house_number + ','} {address?.road + ','} {address?.city}</p>
                {address?.road && response ? <Link to={{
                   pathname: "/Form", 
                   search: `?fullAddress=${encodeURIComponent(fullAddress)}&house_number=${encodeURIComponent(address?.house_number)}&road=${encodeURIComponent(address?.road)}&city=${encodeURIComponent(address?.city)}&state=${encodeURIComponent(address?.state)}&postcode=${encodeURIComponent(address?.postcode)}` 
                   }} className="nav-link">
                  Go to form
                </Link>: <div><LoadingIndicator/></div>}
              </div>
            </Popup>
          </Marker>
        )}
      </MapContainer>
    </div>
  );
}

function SearchBar({ setMarkerPosition, setAddress, zoom, userEmail }) {
  const map = useMap();
  // Maximum number of requests that can be made in a specified time

  useEffect(() => {
    console.log("userEmail", userEmail);
    const provider = new OpenStreetMapProvider({
      params: {
        email: userEmail, // Your authentication for the geocoding provider
        countrycodes: 'gb', // Limit search results to a specific country
        addressdetails: 0, // Include additional address detail in the results
      },
    });

    map.addControl(
      new GeoSearchControl({
        provider,
        style: "bar",
        showMarker: false,
        autoComplete: true,
        autoCompleteDelay: 250,
        searchLabel: "Search for an address",
        notFoundMessage: "Address not found",
        animateZoom: true,
        keepResult: true,
      }),
    );

    const searchContainer = document.getElementsByClassName(
      "leaflet-geosearch-bar"
    )[0];
    searchContainer.parentNode.removeChild(searchContainer);

  }, [map, userEmail]);

  return null;
}

function MarkerLocation({ setMarkerPosition, setAddress, zoom, enableMarkerZoomLevel, setResponse }) {
  // Maximum number of requests that can be made in a specified time
  const MAX_REQUESTS_COUNT = 1;
  // The time in milliseconds after which the counter will be reset
  const INTERVAL_MS = 1000;
  // This will store the number of requests made
  const requestCounter = useRef(0);

  const map = useMap();

  useEffect(() => {
    const interval = setInterval(() => {
      requestCounter.current = 0;
      // console.log("Request counter reset");
    }, INTERVAL_MS);
    return () => {
      clearInterval(interval);
    };
  }, [map]);
  
  useMapEvents({
    click: async (e) => {
      if (requestCounter.current >= MAX_REQUESTS_COUNT) {
        console.log("Exceeded maximum number of requests per second");
        return;
      }
      if(requestCounter.current < MAX_REQUESTS_COUNT && zoom > enableMarkerZoomLevel){
        const { latlng } = e;
          requestCounter.current++;
          setMarkerPosition([latlng.lat, latlng.lng]);
          setResponse(false);
      }
    },
  });

  return null;
}

function ZoomEnd({setZoom}) {

  const map = useMap();

useEffect(() => {
  const onZoomEnd = () => {
    const currentZoom = map.getZoom();
    setZoom(currentZoom);
    // console.log("zoom", currentZoom);
  };
  map.on('zoomend', onZoomEnd);
  return () => {
    map.off('zoomend', onZoomEnd);
  };
}, [map, setZoom]);

return null;
}

function calculateBoundsForCountry(countryName) {
  // Replace this with the actual bounding coordinates for each country
  // Example bounds for the UK:
  // const southwest = L.latLng(49.823809, -8.649357);
  // const northeast = L.latLng(60.856196, 1.771507);

  const southwest = L.latLng(40.823809, -20.649357); // Coordinates for the southwest corner of the UK
  const northeast = L.latLng(63.856196, 15.771507); // Coordinates for the northeast corner of the UK
  
  // const countryLatLngBounds = L.latLngBounds(southwest, northeast);
  
  return L.latLngBounds(southwest, northeast);
}
