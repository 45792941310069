import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import { getDatabase } from "firebase/database"

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: "rent-1111.firebaseapp.com",
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: "rent-1111",
    storageBucket: "rent-1111.appspot.com",
    messagingSenderId: "984831872746",
    appId: "1:984831872746:web:e354e99bc3e618922ad193",
    measurementId: "G-VXMVDFLKGR"
};

const app = firebase.initializeApp(firebaseConfig);
export const auth = app.auth();
//export const firestore = getFirestore(app);
export const database = getDatabase(app);

export const db = firebase.firestore();