import React, {useState} from 'react';

async function resendVerificationEmail( {user}, {sent}, {setSent}) {
    const button = document.getElementById('resendButton');
    button.disabled = true;
    if(!sent){
        try {
            await user.currentUser.sendEmailVerification({
            url: process.env.REACT_APP_CONFIRMATION_EMAIL_REDIRECT,
            });
            setSent(true);
        } catch (error) {
            console.error('Error sending email verification:', error);
            button.disabled = false;
        }
    }
  }

export default function VerifyEmailMsg({ user }) {
    const [sent, setSent] = useState(false);
    const handleResendClick = () => {
        resendVerificationEmail({user}, {sent}, {setSent});
      };
    return (
        <div>
        <p>
            Please verify your email address. A verification link has been sent to {user?.email}.
            If you did not receive the email, please click the button below to resend the verification email.
        </p>
        <button id = "resendButton" onClick={handleResendClick}>
            Resend Verification Email
        </button>
            {sent && <div>Verification email sent successfully!</div>}
        </div>
    );
    }